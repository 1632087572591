@import url("https://fonts.googleapis.com/css2?family=Onest:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-onest: Onest;
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 1rem;
  --font-size-xl: 1.25rem;
  --font-size-9xl: 1.75rem;
  --font-size-3xl: 1.375rem;
  --font-size-33xl: 3.25rem;
  --font-size-12xl: 1.9375rem;
  --font-size-23xl: 2.625rem;
  --font-size-5xl: 1.5rem;
  --font-size-lgi: 1.1875rem;
  --font-size-27xl: 2.875rem;
  --font-size-18xl: 2.3125rem;
  --font-size-lg: 1.125rem;
  --font-size-41xl: 3.75rem;
  --font-size-17xl: 2.25rem;
  --font-size-29xl: 3rem;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #fafafa;
  --color-gray-200: #0e1435;
  --color-gray-300: #171717;
  --color-silver: #c5c1c1;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro: #e1e1e1;
  --color-gainsboro-200: #d9d9d9;
  --color-whitesmoke: #f3f3f3;
  --color-dimgray-300: #747474;
  --color-dimgray-100: #575757;
  --color-dimgray-200: #474b64;
  --color-tomato: #f63333;

  /* Gaps */
  --gap-41xl: 3.75rem;
  --gap-xl: 1.25rem;
  --gap-0: 0;
  --gap-21xl: 2.5rem;
  --gap-31xl: 3.125rem;
  --gap-5xs: 0.5rem;
  --gap-9xl: 1.75rem;
  --gap-lg: 1.125rem;
  --gap-3xs: 0.625rem;
  --gap-71xl: 5.625rem;
  --gap-5xl: 1.5rem;
  --gap-35xl: 3.375rem;
  --gap-17xl: 2.25rem;
  --gap-xs: 0.75rem;

  /* Paddings */
  --padding-93xl: 7rem;
  --padding-21xl: 2.5rem;
  --padding-54xl: 4.5625rem;
  --padding-7xl: 1.625rem;
  --padding-5xl: 1.5rem;
  --padding-22xl: 2.5625rem;
  --padding-20xl: 2.4375rem;
  --padding-61xl: 5rem;
  --padding-xl: 1.25rem;
  --padding-33xl: 3.25rem;
  --padding-15xl: 2.125rem;
  --padding-28xl: 2.9375rem;
  --padding-31xl: 3.125rem;
  --padding-49xl: 4.25rem;
  --padding-25xl: 2.75rem;
  --padding-11xl: 1.875rem;
  --padding-sm: 0.875rem;
  --padding-12xl: 1.9375rem;
  --padding-3xl: 1.375rem;

  /* Border radiuses */
  --br-61xl: 5rem;
  --br-18xl: 2.3125rem;
  --br-9xl: 1.75rem;
  --br-11xl: 1.875rem;
  --br-5xl: 1.5rem;
  --br-17xl: 2.25rem;
}

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
}

::before,
::after {
  --tw-content: '';
}

:root {
  /* light mode colors in RGB */
  --rns-color-green: 5, 150, 105;
  --rns-color-red: 185, 28, 28;
  --rns-text-opacity: 1
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  /* 3 */
  tab-size: 4;
  /* 3 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
  position: relative;
  /* 3 */
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 0.0625rem;
  /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: theme('fontFamily.mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace);
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: normal;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  /* 1 */
  background-color: transparent;
  /* 2 */
  background-image: none;
  /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -0.125rem;
  /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  /* 1 */
  color: theme('colors.gray.400', #9ca3af);
  /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: theme('colors.gray.400', #9ca3af);
  /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}



/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/*
Ensure the default browser behavior of the `hidden` attribute.
*/

[hidden] {
  display: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.rns-link {
  color: #0355BF;
}

#chat-widget-container {
  z-index: 88 !important;
}
html {
  font-family: 'Poppins', 'sans-serif' !important;
}
